import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ReloadOutlined } from "@ant-design/icons";
import { App, Button, Card, ConfigProvider, Divider, Modal, Skeleton } from "antd5";

import { Input, Radio, Select, TextArea } from "components/form_components/Inputs";
import { FeedbackModal } from "components/modals/FeedbackModal";
import { ProductTourCompletionStateResponseTourStateTourIdentifierEnum } from "lib/generated/app-api";
import { useGenerateOutreach } from "lib/hooks/api/outreach/useGenerateOutreach";
import { useUpdateRecordQualification } from "lib/hooks/api/records/useUpdateRecordQualification";
import { useLocalStorage } from "lib/hooks/useLocalStorage";
import { useProductTour } from "lib/hooks/useProductTour";
import Sparkles from "lib/icons/Sparkles";
import { useDialogManager } from "lib/providers/DialogManager";
import { BlueSkeleton } from "lib/themes/BlueSkeleton";
import { blue500, white } from "lib/themes/colors";
import { EventNames, logEvent } from "lib/tracking";
import { getSignalTypes, RecordDetails } from "lib/types/models";
import { toHumanRelativeTimeFromNow } from "lib/utils/relative_time";
import { extractUniqueSuppliersFromRecord } from "lib/utils/stotlesDataUtils";
import { OutreachResult } from "./OutreachResult";
import { useOutreachTracking } from "./tracking";

import css from "./OutreachBuilderModal.module.scss";

import CELEBRATE_ICON from "../../../assets/images/icons/celebrate.svg";

type Intent = "Awareness" | "Meeting";
type Tone =
  | "Friendly"
  | "Relaxed"
  | "Bold"
  | "Witty"
  | "Knowledgeable"
  | "Empathetic"
  | "Professional"
  | "Fun"
  | "Casual";
type Length = "Short" | "Long";

type FormValues = {
  intent: Intent;
  companyInformation?: string;
  senderPersonalisation?: string;
  tone: Tone[];
  length: Length;
  buyerName: string;
  contactName: string;
  contactJobTitle: string;
  numberOfResponses: number;
};

const toneOptions = [
  {
    label: "🙂 Friendly",
    value: "Friendly",
  },
  { label: "😌 Relaxed", value: "Relaxed" },
  { label: "💪 Bold", value: "Bold" },
  { label: "💡 Witty", value: "Witty" },
  { label: "🧠 Knowledgeable", value: "Knowledgeable" },
  { label: "🤗 Empathetic", value: "Empathetic" },
  { label: "💼 Professional", value: "Professional" },
  { label: "😄 Fun", value: "Fun" },
  { label: "🏝️ Casual", value: "Casual" },
];

type Props = {
  buyerName: string;
  contactName: string;
  jobTitle: string;
  contactEmail: string;
  isOpen: boolean;
  onClose: () => void;
  record?: RecordDetails;
};

type Defaults = { intent: Intent; companyInformation?: string; tone: Tone[]; length: Length };

function getRecordPersonalisation(record: RecordDetails) {
  const suppliers = extractUniqueSuppliersFromRecord(record);

  const supplierNames = suppliers.map((s) => s.name).join(", ");

  if (record.expiry_date) {
    const expiryDate = new Date(record.expiry_date);
    const isFuture = expiryDate.getTime() > Date.now();
    return `Mention the current contract ${record.buyer.name} has with ${supplierNames} ${
      isFuture ? "is expiring" : "expired"
    }  ${toHumanRelativeTimeFromNow(record.expiry_date)}`;
  }

  return `Mention the current contract ${record.buyer.name} has with ${supplierNames}`;
}

export function OutreachBuilderModal({
  contactName,
  buyerName,
  jobTitle,
  contactEmail,
  isOpen,
  onClose,
  record,
}: Props) {
  const { message } = App.useApp();
  const [savedDefaults, setSavedDefaults] = useLocalStorage<Defaults>("outreachBuilderDefaults", {
    intent: "Awareness",
    tone: ["Casual"],
    length: "Short",
  });
  const { track } = useOutreachTracking();
  const { control, handleSubmit, formState } = useForm<FormValues>({
    defaultValues: {
      ...savedDefaults,
      buyerName,
      contactName,
      contactJobTitle: jobTitle,
      numberOfResponses: 1,
      senderPersonalisation: record ? getRecordPersonalisation(record) : undefined,
    },
  });
  const { isLoading, mutate, data } = useGenerateOutreach({
    onError: () => message.error("Sorry something has gone wrong"),
  });
  const dialogManager = useDialogManager();
  const [actionTaken, setActionTaken] = useState(false);
  const { tourOpen, permanentlyHide } = useProductTour("outreach_builder_send_feedback");

  const { mutate: mutateRecordQualification } = useUpdateRecordQualification({
    onError: () => message.error("Error updating record qualification"),
    onSuccess: () =>
      logEvent(EventNames.qualificationActioned, {
        Stage: record?.procurement_stage.stage,
      }),
  });

  const onSubmit = handleSubmit((data) => {
    track(formState.submitCount > 1 ? "regenerate" : "generate", data);
    mutate({ ...data, numberOfResponses: Number(data.numberOfResponses) });
    setSavedDefaults({
      intent: data.intent,
      tone: data.tone,
      length: data.length,
      companyInformation: data.companyInformation,
    });
  });

  /**
   * If within the context of a record (in RelatedContacts component) then this callback will update the qualfication status of that record,
   * will also move the contact to actioned (when we add that functionality)
   */
  const updateStatus = () => {
    setActionTaken(true);
    if (record) {
      mutateRecordQualification({
        recordGuid: record.guid,
        score: record.relevance_score,
        signalTypes: getSignalTypes(record.signals),
        procurementStage: {
          id: record.procurement_stage.id,
          stage: record.procurement_stage.stage,
        },
        qualification: "pre_engage_done",
        contextSource: "Outreach builder",
      });
    }
  };

  const onCloseModal = async () => {
    if (actionTaken && tourOpen) {
      const [, isClosed] = dialogManager.openDialog(FeedbackModal, {
        tourIdentifier:
          ProductTourCompletionStateResponseTourStateTourIdentifierEnum.OutreachBuilderSendFeedback,
        title: "Great job! What did you think?",
        description: "You just created your first AI generated outreach message!",
        permanentlyHide,
        icon: <img src={CELEBRATE_ICON} />,
      });
      await isClosed;
    }
    onClose();
  };

  return (
    <ConfigProvider theme={BlueSkeleton}>
      <Modal
        visible={isOpen}
        title={null}
        footer={null}
        onCancel={onCloseModal}
        className={css.modal}
        bodyStyle={{ padding: 0 }}
      >
        <form className={css.content} onSubmit={onSubmit} id="outreach">
          <div className={css.sidePanel}>
            <div className={css.outreachHeader}>
              <h2>Outreach builder</h2>
              <span className={css.subTitle}>
                Create personalised and engaging outreach messaged in seconds.
              </span>
            </div>
            <div className={css.formContent}>
              <div className={css.intentContainer}>
                <h3>I want to...</h3>
                <Controller
                  name="intent"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <>
                      <Card
                        className={
                          field.value === "Awareness" ? css.intentCardSelected : css.intentCard
                        }
                        styles={{ body: { padding: 16 } }}
                        onClick={() => field.onChange("Awareness")}
                        role="button"
                        aria-label="Generate awareness"
                      >
                        <h3>Generate awareness</h3>
                        <span>
                          Engage with buyers early to build awareness around your company, products
                          and services.{" "}
                        </span>
                      </Card>
                      <Card
                        className={
                          field.value === "Meeting" ? css.intentCardSelected : css.intentCard
                        }
                        styles={{ body: { padding: 16 } }}
                        onClick={() => field.onChange("Meeting")}
                        role="button"
                        aria-label="Book a meeting"
                      >
                        <h3>Book a meeting</h3>
                        <span>
                          Lock in time to directly speak with a decision maker and solidify
                          relationships around an upcoming contract expiry{" "}
                        </span>
                      </Card>
                    </>
                  )}
                />
              </div>

              <TextArea
                label="About your products/services (optional)"
                name="companyInformation"
                control={control}
                subLabel="Share details about your company and/or services to create a more engaging message."
                rows={4}
                placeholder={`For example...
[Your company name] are a certified reseller of [insert product/service here]. We are on [Framework name]. `}
              />

              <TextArea
                label="Mention (optional)"
                name="senderPersonalisation"
                control={control}
                subLabel="Better engage with the contact by adding a personal note."
                rows={4}
              />

              <Select
                label="Tone of voice"
                name="tone"
                options={toneOptions}
                control={control}
                rules={{
                  validate: (value) => {
                    if (value && Array.isArray(value) && value.length > 0) {
                      return true;
                    }
                    return "Please select at least one tone of voice";
                  },
                }}
                mode="multiple"
                data-testid="tone-select"
              />

              <Radio
                label="Length"
                name="length"
                control={control}
                options={[
                  { value: "Short", label: "Short" },
                  { value: "Long", label: "Long" },
                ]}
              />
            </div>
          </div>
          <div className={css.dividerVertical} />

          {data ? (
            <div className={css.sidePanel}>
              <h2>Results:</h2>
              <Divider className={css.divider} />
              <div className={css.results}>
                {data.response.map((response) => (
                  <React.Fragment key={response}>
                    <OutreachResult
                      result={response}
                      email={contactEmail}
                      onAction={updateStatus}
                    />
                    <Divider className={css.divider} />
                  </React.Fragment>
                ))}

                <div className={css.generateContainer}>
                  <div className={css.actionItems}>
                    <Button type="default" htmlType="submit">
                      <ReloadOutlined /> Refresh results
                    </Button>
                    <Input
                      name="numberOfResponses"
                      label=""
                      control={control}
                      type="number"
                      max={5}
                      min={1}
                      rules={{ min: 1, max: 5 }}
                      className={css.numberOfResults}
                    />
                  </div>
                  <Button type="link" onClick={() => track("skip")}>
                    <a href={`mailto:${contactEmail}`} onClick={updateStatus} target="_blank">
                      Skip, open email
                    </a>
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <>
              {isLoading ? (
                <div className={css.sidePanel}>
                  <h2>Results:</h2>
                  <Divider className={css.divider} />

                  <Skeleton title paragraph active />
                  <Skeleton paragraph active />
                  <Skeleton paragraph active />
                  <Skeleton paragraph active />
                </div>
              ) : (
                <div className={css.experimentContainer}>
                  <Sparkles className={css.largeIcon} fill={blue500} />
                  <span>Generate outreach messages and they will appear here.</span>
                  <div className={css.generateContainer}>
                    <Button type="primary" htmlType="submit" className={css.generateBtn}>
                      <Sparkles fill={white} className={css.icon} /> Generate
                    </Button>
                    <Input
                      name="numberOfResponses"
                      title="number of results"
                      label=""
                      control={control}
                      type="number"
                      max={5}
                      min={1}
                      rules={{ min: 1, max: 5 }}
                      className={css.numberOfResults}
                    />
                  </div>
                  <Button type="link" onClick={() => track("skip")}>
                    <a href={`mailto:${contactEmail}`} onClick={updateStatus} target="_blank">
                      Skip, open email
                    </a>
                  </Button>
                </div>
              )}
            </>
          )}
        </form>
      </Modal>
    </ConfigProvider>
  );
}
