import React from "react";
import { useForm } from "react-hook-form";
import { Button, Divider, Modal } from "antd5";

import { alertCircle } from "../../lib/icons/untitled_ui/SVGs";
import UIcon from "../../lib/icons/untitled_ui/UIcon";
import { red500 } from "../../lib/themes/colors";
import { EventNames, useTracking } from "../../lib/tracking";
import { formatDate } from "../../lib/utils";
import { DatePicker, Input, Radio, Select } from "../form_components/Inputs";
import {
  calculateReminderDate,
  createCalenderLink,
  generateEventDescription,
  isDateInFuture,
  setHoursOnDate,
} from "./utils";

import css from "./ScheduleReminderModal.module.scss";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  dataType: "notice" | "framework";
  anchorDate?: string | null;
  anchorType?: "expiry" | "close";
  dataId: string;
  dataTitle: string;
};

type ReminderForm = {
  anchorDate: Date;
  reminderInterval: {
    value: number;
    unit: "days" | "weeks" | "months" | "years";
  };
  reminderHour: number;
  calendar: "google" | "office365" | "ics";
  title: string;
};

const reminderUnitOptions = [
  { label: "Days before", value: "days" },
  { label: "Weeks before", value: "weeks" },
  { label: "Months before", value: "months" },
  { label: "Years before", value: "years" },
];

const reminderHourOptions = Array.from({ length: 24 }, (_, i) => ({
  label: `${i.toString().padStart(2, "0")}:00`,
  value: i,
}));

export function ScheduleReminderModal({
  isOpen,
  onClose,
  dataType,
  dataTitle,
  anchorDate,
  anchorType = "expiry",
  dataId,
}: Props) {
  // check if expiry date is in the past or null
  const hasAnchorDate = anchorDate ? new Date(anchorDate) > new Date() : false;
  const {
    control,
    handleSubmit,
    getValues,
    formState: { isValid, isSubmitted, errors },
    setError,
    clearErrors,
  } = useForm<ReminderForm>({
    defaultValues: {
      anchorDate: hasAnchorDate && anchorDate ? new Date(anchorDate) : undefined,
      reminderHour: 9,
      reminderInterval:
        anchorType === "expiry" ? { value: 3, unit: "months" } : { value: 3, unit: "days" },
      calendar: "google",
      title: dataTitle,
    },
  });
  const { logEvent } = useTracking();

  const onSubmit = (data: ReminderForm) => {
    const reminderDate = hasAnchorDate
      ? calculateReminderDate({
          anchorDate: data.anchorDate,
          reminderInterval: data.reminderInterval,
          startHour: data.reminderHour,
        })
      : setHoursOnDate(data.anchorDate, data.reminderHour);

    const link = createCalenderLink(
      {
        start: reminderDate,
        title: `Reminder: ${data.title}`,
        description: generateEventDescription(dataType, dataId, dataTitle, data.calendar),
        duration: [30, "minutes"],
      },
      data.calendar,
    );

    logEvent(EventNames.reminderSavedToCalendar, {
      dataType,
      dataId,
      calendar: data.calendar,
      reminderDate,
    });

    // open the calendar link in a new tab
    window.open(link, "_blank");
    onClose();
  };

  return (
    <Modal
      title="Schedule a calendar reminder"
      open={isOpen}
      onCancel={() => onClose()}
      footer={null}
    >
      <p>
        Remind me to engage with this {dataType}{" "}
        {hasAnchorDate &&
          anchorDate &&
          `before its ${anchorType} date on ${formatDate(new Date(anchorDate))}`}
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={css.reminderTimeInputs}>
          {hasAnchorDate ? (
            <>
              <Input
                name="reminderInterval.value"
                control={control}
                type="number"
                label=""
                width={93}
                className={css.reminderInput}
                min={1}
                rules={{
                  required: "Please enter a reminder value",
                  min: { value: 1, message: "Must be more than 1" },
                }}
                aria-label="Reminder value"
              />
              <Select
                name="reminderInterval.unit"
                control={control}
                options={reminderUnitOptions}
                label=""
                rules={{
                  required: "Please select a reminder unit",
                  validate: (_value) => {
                    const formValues = getValues();
                    if (
                      isDateInFuture({
                        anchorDate: formValues.anchorDate,
                        reminderInterval: formValues.reminderInterval,
                        startHour: formValues.reminderHour,
                      })
                    ) {
                      clearErrors("reminderInterval");
                      return undefined;
                    }
                    setError("reminderInterval", {
                      message: "This date is in the past. Select a different date",
                    });
                    // we're setting it to undefined here to avoid showing the error message on the input field
                    return undefined;
                  },
                }}
                aria-label="Reminder unit"
                data-testid="reminder-unit"
              />
            </>
          ) : (
            <DatePicker
              name="anchorDate"
              label=""
              rules={{
                required: "Please enter a date",
                validate: (value) => {
                  if (value > new Date()) {
                    clearErrors("reminderInterval");
                    return undefined;
                  }
                  setError("reminderInterval", {
                    message: "This date is in the past. Select a different date",
                  });
                  return undefined;
                },
              }}
              control={control}
            />
          )}
          <Select
            name="reminderHour"
            control={control}
            options={reminderHourOptions}
            label=""
            rules={{ required: "Please enter a reminder hour" }}
            aria-label="Reminder hour"
            data-testid="reminder-hour"
            className={css.reminderSelect}
          />
        </div>
        {isSubmitted && errors.reminderInterval && (
          <div className={css.error}>
            <UIcon svg={alertCircle} colour={red500} size={16} /> {errors.reminderInterval.message}
          </div>
        )}
        <Divider />
        <Radio
          name="calendar"
          optionType="default"
          control={control}
          options={[
            { label: "Google Calendar", value: "google" },
            { label: "Office 365", value: "office365" },
            { label: "ICS", value: "ics" },
          ]}
          label="Choose calendar"
          rules={{ required: "Please select a calendar" }}
        />
        <div className={css.footer}>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" htmlType="submit" disabled={isSubmitted && !isValid}>
            Save to calendar
          </Button>
        </div>
      </form>
    </Modal>
  );
}
