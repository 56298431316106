import React, { useEffect, useMemo, useState } from "react";
import { FieldValues, useController } from "react-hook-form";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Input, Tag } from "antd5";
import _ from "lodash";

import PaywallPopover from "components/paywall/PaywallPopover";
import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import { useDebouncedValue } from "lib/debounce";
import { useSearchOrganisations } from "lib/hooks/api/organisations/useSearchOrganisations";
import { useCheckSubscription } from "lib/providers/ProHelper";
import { OrgPrimaryRole, OrgSortBy, OrgSortOrder } from "lib/types/graphQLEnums";
import { ALL_COMPETITORS_TOKEN, ALL_PARTNERS_TOKEN } from "lib/types/models";
import { isDefined, simpleArrayDedupe } from "lib/utils";
import { useSignalSettingsGQL } from "../../lib/hooks/api/teams/useSignalSettingsGQL";
import SuppliersDropdown, {
  SupplierOption,
} from "./dropdown_list_selects/new_supplier_selects/SuppliersDropdown";
import { SelectProps } from "./Inputs";

import css from "./NewSupplierSelect.module.scss";

type SimpleOrg = {
  id: string;
  name: string;
};

function convertOrgToSupplierOption(orgs?: SimpleOrg[] | null): SupplierOption[] {
  if (!orgs) {
    return [];
  }

  return orgs.map((o) => ({
    label: o.name,
    value: o.id,
  }));
}

// function getToolTip(supplierId: string, competitors: SupplierOption[], partners: SupplierOption[]) {
//   if (competitors.find((s) => s.value === supplierId)) {
//     if (partners.find((s) => s.value === supplierId)) {
//       return "Competitor and partner signal";
//     }
//     return "Competitor signal";
//   }

//   if (partners.find((s) => s.value === supplierId)) {
//     return "Partner signal";
//   }
// }

/**
 * If all competitor guids or all partner guids are selected - add the ALL_PARTNERS and/or ALL_COMPETITORS tokens.
 * Reason being; if a user saves a supplier as a competitor or a partner after creating a saved view - we want to be
 * able to have a token which would include this new guid in any pre-existing views with ALL competitors or ALL partners
 */
function checkIfAllSignalsSelected(
  selectedSuppliers: string[],
  competitors: SupplierOption[],
  partners: SupplierOption[],
): string[] {
  if (selectedSuppliers.length === 0) {
    return [];
  }

  const allCompetitorsGuids = competitors.map((s) => s.value);
  const allPartnersGuids = partners.map((s) => s.value);

  const containsAllCompetitors = allCompetitorsGuids.every((s) => selectedSuppliers.includes(s));
  const containsAllPartners = allPartnersGuids.every((s) => selectedSuppliers.includes(s));

  if (containsAllCompetitors) {
    selectedSuppliers.push(ALL_COMPETITORS_TOKEN);
  }

  if (containsAllPartners) {
    selectedSuppliers.push(ALL_PARTNERS_TOKEN);
  }

  return selectedSuppliers;
}

type FieldProps<T extends FieldValues> = Omit<SelectProps<T>, "options">;

export function NewSupplierSelect<T extends FieldValues>(props: FieldProps<T>) {
  const [searchText, setSearchText] = useState<string>("");
  const [debouncedText] = useDebouncedValue(searchText, 300);
  const [isOpen, setIsOpen] = useState(false);

  const { field } = useController(props);

  const { authorised: hasSuppliers } = useCheckSubscription("SUPPLIERS", {
    "Context source": "Supplier filter",
  });
  const [supplierLabels, setSupplierLabels] = useState<Record<string, string>>({});

  const { data: signals, isLoading: isLoadingSignals } = useSignalSettingsGQL();

  const competitors = useMemo(
    (): SupplierOption[] => convertOrgToSupplierOption(signals?.competitors),
    [signals?.competitors],
  );

  const partners = useMemo(
    (): SupplierOption[] => convertOrgToSupplierOption(signals?.partners),
    [signals?.partners],
  );

  /**
   * UseMemo of internalFilterValue is primarily used to handle the case where the user selects the ALL_COMPETITORS or ALL_PARTNERS token
   * or it is already present in the filters (from a saved view for example)
   */
  const internalFilterValue = useMemo((): string[] => {
    if (!field.value) {
      return [];
    }

    let filterValue: string[] = field.value.filter(isDefined);

    // If ALL_COMPETITORS token exists in filters, then replace it with all competitor IDs
    if (filterValue.includes(ALL_COMPETITORS_TOKEN)) {
      filterValue = filterValue.concat(competitors.map((s) => s.value));
    }

    // If ALL_PARTNERS token exists in filters, then replace it with all partner IDs
    if (filterValue.includes(ALL_PARTNERS_TOKEN)) {
      filterValue = filterValue.concat(partners.map((s) => s.value));
    }

    // Ensure that all partners and all competitors tokens are removed from the filter value
    return simpleArrayDedupe(filterValue).filter(
      (val) => ![ALL_COMPETITORS_TOKEN, ALL_PARTNERS_TOKEN].includes(val),
    );
  }, [competitors, field.value, partners]);

  // On initial load, fetch the labels for the selected suppliers
  // This doesn't include textSearch
  const { data: initialOrganisations } = useSearchOrganisations(
    {
      textSearch: "",
      sortBy: OrgSortBy.Relevance,
      sortOrder: OrgSortOrder.Desc,
      primaryRole: OrgPrimaryRole.Supplier,
      ids: internalFilterValue,
      limit: Math.max(internalFilterValue.length, 1),
      page: 1,
    },
    false,
    false,
    {
      enabled: internalFilterValue.length > 0 && Object.keys(supplierLabels).length === 0,
    },
  );

  useEffect(() => {
    if (initialOrganisations) {
      setSupplierLabels((prev) => {
        const newLabels = { ...prev };
        if (initialOrganisations?.searchOrganisations.orgs) {
          initialOrganisations.searchOrganisations.orgs.forEach((s) => {
            newLabels[s.id] = s.name;
          });
        }
        return newLabels;
      });
    }
  }, [initialOrganisations]);

  function handleSuppliersSelection(
    modifiedSuppliers: SupplierOption[],
    operation: "add" | "remove" | "clear",
  ) {
    let newSelection: SupplierOption[] = [];

    if (internalFilterValue.length > 0 && Object.keys(supplierLabels).length > 0) {
      newSelection = internalFilterValue.map((s: string) => ({
        label: supplierLabels[s],
        value: s,
      }));
    }

    setSupplierLabels((prev) => {
      const newLabels = {
        ...prev,
      };
      modifiedSuppliers.forEach((s) => {
        newLabels[s.value] = s.label;
      });
      return newLabels;
    });
    switch (operation) {
      case "add": {
        newSelection = _.uniqBy([...newSelection, ...modifiedSuppliers], "value");
        break;
      }
      case "remove": {
        newSelection = newSelection.filter(
          (s) => !modifiedSuppliers.some((sup) => sup.value === s.value),
        );
        break;
      }
      case "clear": {
        newSelection = [];
        break;
      }
    }

    const newSelectionValues = checkIfAllSignalsSelected(
      newSelection.map((s) => s.value),
      competitors,
      partners,
    );
    field.onChange(newSelectionValues);
  }
  // TODO: cleanup once we decide to keep or remove
  // hide for now while we test with users
  // function getToolTip(supplierId: string) {
  //   if (signals?.competitors?.find((s) => s.id === supplierId)) {
  //     if (signals?.partners?.find((s) => s.id === supplierId)) {
  //       return "Competitor and partner signal";
  //     }
  //     return "Competitor signal";
  //   }

  //   if (signals?.partners?.find((s) => s.id === supplierId)) {
  //     return "Partner signal";
  //   }
  // }
  // const signalIds = competitors.concat(partners).map((s) => s.value);

  const showTags = internalFilterValue.length > 0 && !isOpen;

  return (
    <PaywallPopover featureType="SUPPLIERS">
      <div
        aria-label="supplierSelectContainer"
        onBlur={(e) => {
          if (e.currentTarget.contains(e.relatedTarget as Node)) {
            return;
          }
          setIsOpen(false);
        }}
      >
        <div tabIndex={0} className={css.container}>
          <Input
            aria-label="supplierSearchInput"
            className={hasSuppliers ? css.inputField : css.disabledInputField}
            onClick={() => {
              if (hasSuppliers) {
                setIsOpen(true);
              }
            }}
            onChange={(e) => {
              const newValue = e.currentTarget.value.trim();

              // if clearing the search text, close the dropdown
              if (newValue.length === 0 && searchText.length > 0) {
                setIsOpen(false);
              }

              // if we're typing, open the dropdown
              if (newValue.length > 0 && !isOpen) {
                setIsOpen(true);
              }

              setSearchText(e.currentTarget.value.trim());
            }}
            placeholder={isOpen ? undefined : "Search competitors, partners and other suppliers"}
          />
          <SuppliersDropdown
            handleSuppliersSelection={handleSuppliersSelection}
            textSearch={debouncedText}
            isOpen={isOpen}
            value={internalFilterValue}
            competitors={competitors}
            partners={partners}
            isLoading={isLoadingSignals}
          />
          {showTags && (
            <>
              <div className={css.filterLabelDiv} aria-label="filterLabel">
                <span>{internalFilterValue.length} selected</span>
                <Button
                  className={css.clearButton}
                  onClick={() => handleSuppliersSelection([], "clear")}
                >
                  Clear
                </Button>
              </div>
              <div className={css.supplierSelections} aria-label="supplierSelections">
                <div className={css.tagContainer}>
                  {internalFilterValue.map((i: string) => {
                    const supplier = { label: supplierLabels[i], value: i };
                    // const isSignal = signalIds.includes(supplier.value);

                    return (
                      <Tag
                        className={css.tag}
                        onClose={() => handleSuppliersSelection([supplier], "remove")}
                        closeIcon={<CloseOutlined />}
                        key={supplier.value}
                        title={supplier.label}
                      >
                        {/* 
                      // TODO: cleanup once we decide to keep or remove
                      // hide signal information for now to test with users
                      {isSignal && (
                        <Signal className={css.signalIcon} size={16} label="signalIcon" />
                      )}
                        */}
                        <EllipsisTooltipText
                          fullText={supplier.label}
                          textProps={{ className: css.tagLabel }}
                        />
                      </Tag>
                    );
                    // TODO: cleanup once we decide to keep or remove
                    //   return isSignal ? (
                    //     <Tooltip
                    //       title={getToolTip(supplier.value, competitors, partners)}
                    //       key={supplier.value}
                    //       placement="bottom"
                    //     >
                    //       {tag}
                    //     </Tooltip>
                    //   ) : (
                    //     {tag}
                    //   );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </PaywallPopover>
  );
}
