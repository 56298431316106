import React from "react";
import classNames from "classnames";

// The next line is disabled because,
// we are dynamically generating classNames from props
// eslint-disable-next-line css-modules/no-unused-class
import css from "./Tag.module.scss";

export type TagProps = {
  id?: string;
  label?: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
  ariaLabel?: string;
  variant?: "default" | "active" | "success" | "warning" | "error";
};

export default function Tag({
  id,
  label,
  icon,
  onClick,
  isLoading,
  disabled,
  className,
  ariaLabel,
  variant = "default",
}: TagProps) {
  if (isLoading) {
    return <div className={css.loadingContainer} role="status" aria-live="polite" />;
  }

  const containerClassName = classNames(css.container, css[variant], className);

  const labelClassName = classNames(css.label, {
    [css.clickableLabel]: onClick && !disabled && !icon,
  });

  const handleContainerClick = () => {
    if (onClick && !disabled && !icon) {
      onClick();
    }
  };

  // Extracted onClick handler for the icon
  const handleIconClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    if (onClick && !disabled) {
      onClick();
    }
  };

  return (
    <div
      id={id}
      className={containerClassName}
      onClick={handleContainerClick}
      tabIndex={onClick && !disabled && !icon ? 0 : undefined}
      role={onClick && !disabled && !icon ? "button" : undefined}
      aria-label={ariaLabel ?? label}
    >
      {label && <p className={labelClassName}>{label}</p>}
      {icon && (
        <span
          className={classNames({
            [css.clickableIcon]: onClick && !disabled,
          })}
          onClick={handleIconClick}
          role={onClick && !disabled ? "button" : undefined}
          tabIndex={onClick && !disabled ? 0 : undefined}
          aria-label={`${ariaLabel ?? ""}-icon`}
        >
          {icon}
        </span>
      )}
    </div>
  );
}
