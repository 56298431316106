import React, { useCallback } from "react";
import { SelectProps } from "antd5/lib/select";

import { AutocompleteMultiselect } from "components/form_components/AutocompleteMultiselect";
import StotlesVerifiedIcon from "lib/core_components/StotlesVerifiedIcon";
import { SearchBuyersResponse } from "lib/StotlesApi";
import { useStotlesApi } from "lib/stotlesApiContext";
import { BuyerSummary, RecordListSummary } from "lib/types/models";
import { pluralise } from "lib/utils";

type BuyerMultiselectProps<I extends "guid" | "id"> = {
  specificList?: RecordListSummary;
  optionLabelProp?: SelectProps["optionLabelProp"];
  customPlaceholder?: string;
  noMaxTagCount?: boolean;
  countryCodes?: string[];
  disabled?: boolean;
  showVerified?: boolean;
  id?: string;
  identifier: I;
  buyerIds: BuyerSummary[I][] | undefined;
  onBuyerIdsChange: (newIds: BuyerSummary[I][]) => void;
};

function BuyerMultiselect<I extends "guid" | "id">(props: BuyerMultiselectProps<I>): JSX.Element {
  const api = useStotlesApi();
  const { specificList, customPlaceholder, countryCodes, showVerified, identifier } = props;

  const searchByText = useCallback(
    (text: string, limit?: number): Promise<SearchBuyersResponse> =>
      specificList
        ? api.searchBuyersInList(
            { sort: "relevance", text, limit, country: countryCodes },
            specificList.company_id,
            specificList.id,
          )
        : api.searchBuyers({
            sort: "relevance",
            text,
            limit,
            country: countryCodes,
          }),
    [api, specificList, countryCodes],
  );

  const searchByIds = useCallback(
    (ids: BuyerSummary[I][]) => {
      const key = props.identifier === "guid" ? "guids" : "id";
      return api.searchBuyers({ [key]: ids });
    },
    [api, props.identifier],
  );

  const onBuyerIdsChange = useCallback(
    (newIds: BuyerSummary[I][]) => {
      //this is a hack to convert the ids to the correct type, after the ant 5 upgrade
      if (identifier === "id") {
        newIds = newIds.map((id) =>
          typeof id === "string" ? parseInt(id) : id,
        ) as BuyerSummary[I][];
      }
      props.onBuyerIdsChange(newIds);
    },
    [identifier, props],
  );

  return (
    <AutocompleteMultiselect<BuyerSummary, SearchBuyersResponse, BuyerSummary[I]>
      itemIds={props.buyerIds || []}
      onItemsChange={onBuyerIdsChange}
      searchByText={searchByText}
      searchByIds={searchByIds}
      placeholder={customPlaceholder || "Search Buyers..."}
      displayedAttr="name"
      disabled={props.disabled}
      narrowSearchLabel={(numMatching) =>
        `${pluralise(numMatching, "buyer")} found, narrow search to add all`
      }
      getIdentifier={(buyer) => buyer[identifier]}
      allowClear
      renderIcon={
        showVerified ? (b) => b.is_stotles_verified && <StotlesVerifiedIcon /> : undefined
      }
      {...props}
    />
  );
}

export default BuyerMultiselect;
