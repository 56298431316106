import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  SearchOrganisationsQuery,
  SearchOrganisationsRequest,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlInfiniteQuery, useGraphQlQuery } from "../useGraphQlClient";

const searchOrganisations = graphql(`
  query searchOrganisations(
    $request: SearchOrganisationsRequest!
    $includeNotices: Boolean!
    $includeAliases: Boolean!
  ) {
    searchOrganisations(SearchOrganisationsRequest: $request) {
      orgs {
        id
        address
        countryCode
        email
        isStotlesVerified
        name
        phone
        postalCode
        town
        url
        primaryRole
        noticeCount @include(if: $includeNotices)
        aliases @include(if: $includeAliases)
      }
      total
    }
  }
`);

type SearchOrganisationsResponse = SearchOrganisationsQuery["searchOrganisations"];
export type OrgBasicInfo = SearchOrganisationsResponse["orgs"][0];

export function useSearchOrganisations(
  searchOrgsRequest: SearchOrganisationsRequest,
  includeNotices: boolean = false,
  includeAliases: boolean = false,
  options?: UseQueryOptions<SearchOrganisationsQuery, unknown, SearchOrganisationsQuery, QueryKey>,
) {
  return useGraphQlQuery<
    SearchOrganisationsQuery,
    { request: SearchOrganisationsRequest; includeNotices: boolean; includeAliases: boolean }
  >(
    ["organisations", searchOrgsRequest, includeNotices, includeAliases],
    searchOrganisations,
    [
      {
        request: searchOrgsRequest,
        includeNotices: includeNotices,
        includeAliases: includeAliases,
      },
    ],
    {
      ...options,
    },
  );
}

export function useInfiniteSearchOrganisations(
  searchOrgsRequest: SearchOrganisationsRequest,
  includeNotices: boolean = false,
  includeAliases: boolean = false,
) {
  return useGraphQlInfiniteQuery<
    SearchOrganisationsQuery,
    { request: SearchOrganisationsRequest; includeNotices: boolean; includeAliases: boolean }
  >(
    ["organisations", searchOrgsRequest, includeNotices, includeAliases],
    searchOrganisations,
    [
      {
        request: searchOrgsRequest,
        includeNotices: includeNotices,
        includeAliases: includeAliases,
      },
    ],
    {
      getNextPageParam: (lastPage, allPages) => {
        const totalSuppliers = lastPage.searchOrganisations.total;
        const totalLoadedSuppliers = allPages.reduce(
          (acc, page) => acc + page.searchOrganisations.orgs.length,
          0,
        );
        return totalSuppliers > totalLoadedSuppliers ? allPages.length + 1 : undefined;
      },
      getPreviousPageParam: (_, allPages) => allPages.length - 1 || 1,
    },
  );
}
