import React, { useState } from "react";
import { Input, Tag } from "antd5";

import css from "./CandidateAliasSelection.module.scss";

type Props = {
  aliases: string[];
  setAliases: (aliases: string[]) => void;
  onAliasRemove: (alias: string) => void;
  adminReview: boolean | undefined;
};

function CandidateAliasSelection({
  aliases,
  setAliases,
  onAliasRemove,
  adminReview,
}: Props): JSX.Element {
  const [inputValue, setInputValue] = useState("");

  const onAliasSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue.trim()) {
      setInputValue("");
      e.preventDefault();
      setAliases([...aliases, inputValue.trim()]);
    }
  };

  const removeAlias = (removedAlias: string) => {
    setAliases(aliases.filter((alias) => alias !== removedAlias));
    onAliasRemove(removedAlias);
  };

  return (
    <div className={css.aliasContainer}>
      <h3>{adminReview ? "Candidate aliases" : "Add aliases"}</h3>
      {adminReview != true && (
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={onAliasSubmit}
          placeholder="Type and press Enter"
        />
      )}

      <div className={css.tagContainer}>
        {aliases.map((alias: string, index: number) => (
          <Tag key={index} closable onClose={() => removeAlias(alias)}>
            {alias}
          </Tag>
        ))}
      </div>
    </div>
  );
}

export default CandidateAliasSelection;
