import React from "react";

import { useContactExport } from "../../lib/hooks/api/contacts/useContactExport";
import { useContacts } from "../../lib/hooks/api/contacts/useContacts";
import { ExportButtonGraphql } from "../actions/ExportButtonGraphql";
import { ContactFilters, convertFiltersToSearchContactsRequest } from "./contactUtils";

type ExportContactsButtonProps = {
  filters: ContactFilters;
  selectedContacts?: string[];
};

function ExportContactsButton({ filters, selectedContacts }: ExportContactsButtonProps) {
  const { data: contactSearch, isLoading } = useContacts(
    convertFiltersToSearchContactsRequest(filters),
  );

  const contactExportMutation = useContactExport();

  const totalResults =
    selectedContacts && selectedContacts.length > 0
      ? selectedContacts.length
      : contactSearch?.contacts.totalResults || 0;

  const contactSearchRequest = convertFiltersToSearchContactsRequest(filters, totalResults);

  return (
    <ExportButtonGraphql
      isLoadingTotalResults={isLoading}
      totalResults={totalResults}
      exportMutation={contactExportMutation}
      exportVariables={{
        filters: contactSearchRequest,
        selectedContacts: { contactIds: selectedContacts },
      }}
    />
  );
}

export default ExportContactsButton;
