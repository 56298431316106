import * as React from "react";
import { DevCycleProvider, DevCycleUser } from "@devcycle/react-client-sdk";
import { captureException, ErrorBoundary, withScope } from "@sentry/react";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { App, ConfigProvider, Layout } from "antd5";
import classnames from "classnames";
import userflow from "userflow.js";

import { AdminBar } from "components/admin_bar/AdminBar";
import AppLayoutNavbar from "components/app_layout/AppLayoutNavbar";
import { APIError } from "lib/Api";
import { UserDataProvider } from "lib/data_providers/UserDataProvider";
import { OpenAPIProvider, STOTLES_OPEN_API } from "lib/openApiContext";
import { DialogManagerProvider } from "lib/providers/DialogManager";
import { PaywallManagerProvider } from "lib/providers/Paywall";
import { ProHelperProvider } from "lib/providers/ProHelper";
import { RecordViewerProvider } from "lib/providers/RecordViewer";
import { ResponsivenessSvcProvider } from "lib/providers/Responsive";
import StotlesAPI from "lib/StotlesApi";
import { ApiContextProvider } from "lib/stotlesApiContext";
import * as tracking from "lib/tracking";
import { IntegrationAPIProvider } from "../../lib/integrationApiContext";
import {
  CreateStotlesDataProvidersContainer,
  StotlesDataContextProvider,
} from "../../lib/providers/StotlesData";
import BookADemoFromUrl from "../account_management/BookADemoFromUrl";
import { DefaultTheme } from "./DefaultTheme";
import SmallScreenWarning from "./SmallScreenWarning";

import css from "./AppLayout.module.scss";

const HIDE_ADMIN_BAR_KEY = "hideAdminBar";

const STOTLES_API = new StotlesAPI();
const REACT_QUERY_CLIENT = new QueryClient({
  mutationCache: new MutationCache({
    onError: (err, _variables, _context, mutation) => {
      withScope((scope) => {
        scope.setContext("mutation", {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
        });
        if (mutation.options.mutationKey) {
          scope.setFingerprint(Array.from(mutation.options.mutationKey) as string[]);
        }
        captureException(err);
      });
    },
  }),
  queryCache: new QueryCache({
    onError: (err, query) => {
      withScope((scope) => {
        scope.setContext("query", { queryHash: query.queryHash });
        captureException(err);
      });
    },
  }),
  defaultOptions: {
    queries: {
      staleTime: 10000,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (error instanceof APIError) {
          if (error.statusCode === 504) {
            return false;
          }
        }
        return failureCount < 3;
      },
    },
  },
});

const devCycleUserDetails: DevCycleUser | undefined = window.currentUser
  ? {
      user_id: window.currentUser.guid,
      customData: {
        company_id: window.currentUser.company.guid,
        company_name: window.currentUser.company.name,
        team_id: window.currentUser.team.id,
        team_name: window.currentUser.team.name,
        // not sure how useful this is as a string, but it's here for now
        data_types: JSON.stringify(window.currentUser.subscribed_data_types),
      },
    }
  : undefined;

type Props<T extends object> = {
  pageName?: string;
  pageViewProps?: Record<string, string | number | undefined | Record<string, unknown> | string[]>; // These will be added to the page view event first triggered when page is opened
  hideMenuItems?: boolean;
  hideUserItems?: boolean;
  displayCustomAction?: React.ReactNode;
  disableLogoNav?: boolean; // If true, navigating through logo click is disabled
  trackingData?: tracking.EventData; // These feed through to all tracking events happening on this page
  contentClassName?: string;
  textLogo?: boolean;
  AdminBarComp?: React.ComponentType<T>;
  adminBarContent?: () => React.ReactNode;
};

function AppLayout<T extends object>({
  children,
  pageName,
  pageViewProps,
  hideMenuItems,
  hideUserItems,
  displayCustomAction,
  disableLogoNav,
  trackingData,
  contentClassName,
  textLogo,
  adminBarContent,
}: React.PropsWithChildren<Props<T>>): JSX.Element {
  // TODO: Read current user from local storage or a cookie.
  const { flash, guestUser } = window;
  const { message } = App.useApp();

  React.useEffect(() => {
    if (guestUser) {
      tracking.identifyByEmail(guestUser);
    }
  }, [guestUser]);

  React.useEffect(() => {
    if (window.guestUser) {
      tracking.identifyByEmail(window.guestUser);
    }
  }, []);

  React.useEffect(() => {
    tracking.pageView(pageName, {
      ...pageViewProps,
    });
  }, [pageName, pageViewProps]);

  React.useEffect(() => {
    for (const [flashType, flashMessage] of flash) {
      if (!flashMessage) continue;
      let messageFn;
      if (flashType === "error") {
        messageFn = message["error"];
      } else if (flashType === "alert") {
        messageFn = message["warning"];
      } else {
        messageFn = message["info"];
      }
      messageFn(flashMessage, 5);
    }
  }, [flash, message]);

  const stotlesData = React.useMemo(
    () => CreateStotlesDataProvidersContainer(STOTLES_API, STOTLES_OPEN_API),
    [],
  );

  const currentUser = window.currentUser;
  const USERFLOW_TOKEN = window.userflowToken;

  if (currentUser && USERFLOW_TOKEN) {
    userflow.init(USERFLOW_TOKEN);
    userflow.identify(
      currentUser?.guid,
      {
        name: currentUser?.first_name + " " + currentUser?.last_name,
        first_name: currentUser?.first_name,
        last_name: currentUser?.last_name,
        email: currentUser?.email,
        data_types: currentUser?.active_data_types,
        signed_up_at: currentUser?.created_at,
        device_type: window.innerWidth > 992 ? "Desktop" : "Mobile",
      },
      {
        signature: window.userflowUserSignature,
      },
    );
    userflow.group(
      currentUser?.company.guid,
      {
        name: currentUser?.company.name,
        payment_type: currentUser?.company.payment_type || "Not set",
        signed_up_at: currentUser?.company.created_at,
      },
      { signature: window.userflowGroupSignature },
    );
  }

  // Currently, the order of these is very important (and can cause some errors if it isn't
  // exactly right).
  // TODO: consider refactoring into a single object?
  return (
    <ConfigProvider prefixCls="ant5" theme={DefaultTheme}>
      <App>
        <ErrorBoundary showDialog>
          <DevCycleProvider
            config={{ sdkKey: window.dev_cycle_sdk_key, user: devCycleUserDetails }}
          >
            <QueryClientProvider client={REACT_QUERY_CLIENT}>
              <ApiContextProvider api={STOTLES_API}>
                <IntegrationAPIProvider>
                  <OpenAPIProvider>
                    <UserDataProvider>
                      <ResponsivenessSvcProvider>
                        <Layout className={css.pageLayout}>
                          <StotlesDataContextProvider instance={stotlesData}>
                            <PaywallManagerProvider
                              trackingPageName={pageName}
                              trackingExtraProps={pageViewProps}
                            >
                              <DialogManagerProvider>
                                <BookADemoFromUrl>
                                  <ProHelperProvider>
                                    <RecordViewerProvider>
                                      <tracking.TrackingProvider data={trackingData ?? {}}>
                                        <AdminBar content={adminBarContent ?? (() => null)} />
                                        <AppLayoutNavbar
                                          hideMenuItems={hideMenuItems}
                                          hideUserItems={hideUserItems}
                                          displayCustomAction={displayCustomAction}
                                          disableLogoNav={disableLogoNav}
                                          textLogo={textLogo}
                                        />
                                        <SmallScreenWarning />
                                        <Layout.Content
                                          className={classnames(css.pageContent, contentClassName)}
                                        >
                                          {children}
                                        </Layout.Content>
                                      </tracking.TrackingProvider>
                                    </RecordViewerProvider>
                                  </ProHelperProvider>
                                </BookADemoFromUrl>
                              </DialogManagerProvider>
                            </PaywallManagerProvider>
                          </StotlesDataContextProvider>
                        </Layout>
                      </ResponsivenessSvcProvider>
                    </UserDataProvider>
                  </OpenAPIProvider>
                </IntegrationAPIProvider>
              </ApiContextProvider>
              {sessionStorage.getItem(HIDE_ADMIN_BAR_KEY) !== "true" && (
                <ReactQueryDevtools
                  initialIsOpen={false}
                  position="top-left"
                  toggleButtonProps={{ className: css.reactQuery }}
                />
              )}
            </QueryClientProvider>
          </DevCycleProvider>
        </ErrorBoundary>
      </App>
    </ConfigProvider>
  );
}

export function withAppLayout<T extends object>(
  Component: React.ComponentType<T>,
  appLayoutProps: Props<T> | ((props: T) => Props<T>),
): (props: T) => JSX.Element {
  return function (props: T): JSX.Element {
    const { AdminBarComp, ...rest }: Props<T> =
      typeof appLayoutProps === "function" ? appLayoutProps(props) : appLayoutProps;

    return (
      <AppLayout
        // this is helpful for when you need the appLayoutProps to be dynamic, eg. properties
        // for an amplitude event which are based off the props, (buyer id in BuyerDetailsPage
        // for example)
        {...rest}
        // use a function to only trigger the render in the admin bar itself
        // for now feed same props from rails as the main page comp
        adminBarContent={() => (AdminBarComp ? <AdminBarComp {...props} /> : null)}
      >
        <Component {...props} />
      </AppLayout>
    );
  };
}
